import * as button from "../styles/buttons.module.scss"
import Contact from "../components/contact"
import FitnessServices from "../components/fitness-services"
import Layout from "../components/layout"
import { Link } from "gatsby"
import * as React from "react"
import { useState, useEffect } from "react"
import Seo from "../components/seo"
import * as styles from "../styles/index.module.scss"

const IndexPage = () => {
  // console.log(process.env.GATSBY_GOOGLE_CLIENT_ID)
  // console.log(process.env.GATSBY_GOOGLE_API_KEY)

  const [top, setTop] = useState(0)
  let speed = 3
  useEffect(() => {
    const handleScroll = () => {
      // Az  "500"-as érték a csúszó képet tartalmazó div magassága, ami a benne lévő kép pozíciójának a számításához szükséges. A "speed" gyorsítható a kép csúszása a scrollozáshoz képest.
      let newTop =
        (speed * (500 * window.pageYOffset)) / document.body.scrollHeight
      setTop(newTop)
    }
    window.addEventListener("scroll", handleScroll)

    return () => window.removeEventListener("scroll", handleScroll)
  })
  useEffect(() => {
    setTop((speed * (500 * window.pageYOffset)) / document.body.scrollHeight)
  }, [speed])

  return (
    <Layout>
      <Seo title="Home" />
      <div id={styles.container}>
        <section id={styles.bar1}>
          <div id={styles.text}>
            <h1>Kovács Gabriella</h1>
            <p>Fedezd fel magadban az erőt és légy egészségesebb velem!</p>
          </div>
          <div id={styles.picture}>
            <div id={styles.image}></div>
            <div id={styles.buttonContainer}>
              <Link
                className={`${button.buttons} ${button.buttonLink1}`}
                to="/kovacs-gabi-bemutatkozas/"
              >
                Bemutatkozom
              </Link>

              <Link
                className={`${button.buttons} ${button.buttonLink2}`}
                to="/calendar/"
              >
                Foglalj időpontot
              </Link>
            </div>
          </div>
        </section>

        <section id={styles.bar2}>
          <div className={styles.container}>
            <div id={styles.text}>
              <h2>Szia! Gabi vagyok</h2>
              <h3>aerobic és személyi edző</h3>
              <p>
                A sport már gyerekkorom óta része az életemnek és immár 5 éve,
                hogy a sportedzői tevékenység és a táplálkozási tanácsadás lett
                a hivatásom is. Ugynakkor már 25 éve tartok fitnesz edzéseket,
                amit korábban a munkám mellett végeztem Szegeden, Budapesten
                majd Budaörsön. <br />
                <b>
                  Hiszem, hogy a rendszeres mozgás és az ehhez igazodó megfelelő
                  táplálkozás az egészséges élet alapja.
                </b>{" "}
                Arra bíztatok mindenkit, hogy ezt a hitvallást tegye magáévá és
                velem együtt mozogjon nagyon sokat! Mindez csakis elhatározás
                kérdése, amiben egy jó edző sokat segíthet!
              </p>
            </div>
            <div id={styles.picture}>
              <div id={styles.image}></div>
            </div>
          </div>
        </section>

        <section id={styles.bar3}>
          <div className={styles.container}>
            <div id={styles.text}>
              <h2>A módszertanom</h2>
              <h3>hogy valóban segítsek</h3>
              <p>
                Szinte bármely korban elkezdhetsz mozogni, ha változtatni
                szeretnél. Rengeteg betegséget megelőzhetsz a rendszeres
                testmozgással! Azonban nem elég csak a mozgásra összpontosítani!
                <br />
                Rendkívül fontos az is, hogy hogyan táplálkozol és hogyan
                pihensz.{" "}
                <b>
                  A testmozgás, a táplálkozás és a pihenés mint egy
                  szentháromság, együttesen hatnak az egészségedre!
                </b>{" "}
                Meggyőződésem, hogy csak együtt lehet kezelni őket! Táplálkozz
                jól, adj meg minden fontos tápanyagot a testednek, mozogj sokat
                és pihenj eleget! Egyszerű mint az egyszeregy, de azért nem
                olyan könnyű be is tartani...
              </p>

              <div id={styles.buttonContainer}>
                <Link
                  className={`${button.buttons} ${button.buttonLink2}`}
                  to="/kovacs-gabi-bemutatkozas/"
                >
                  Tudj meg többet...
                </Link>
              </div>
            </div>

            <div className={styles.picture}>
              {/* ABLAKBAN CSÚSZKÁLÓ KÉP */}
              {/* Ezt a feltételt azért tettem be, hogy oldalfrissítéskor is érvényesüljön a kép pozíciója. Ha nincs benne, akkor az alapbeállítás miatt a kép elcsúszik */}
              {/* A 300%-os magasság azért szükséges, hogy a kép a jóval nagyobb legyen mint a div, így a div úgy viselkedik, mint egy ablak a nagyobb képen */}
              {/* A "top-speed*200" és left értékekkel szabályozható, hogy a kép mely része legyen látható az ablakban*/}
              {top !== 0 ? (
                <img
                  src={`gym.jpg`}
                  alt="by Danielle Cerullo"
                  style={{
                    transform: `translate(0px,${top - speed * 200}px)`,
                    left: "-310px",
                    height: "300%",
                  }}
                />
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </section>

        <FitnessServices />

        <Contact />
      </div>
    </Layout>
  )
}

export default IndexPage
